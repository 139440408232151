import $ from 'jquery';
let windowWidth = $(window).width();

$(function () {
	$('a[href^="#"]').on('click', function () {
		var adjust = $('.l-header').outerHeight();
		var speed = 400;
		var href = $(this).attr('href');
		var target = $(href == '#' || href == '' ? 'html' : href);
		var position = target.offset().top - adjust;
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});

	if (windowWidth < 768) {
		$('.spMenu').on('click', function () {
			$(this).toggleClass('is-open');
			$('.l-header__nav').toggleClass('is-open');
		});
		$('.l-header__nav a').on('click', function () {
			$('.spMenu').click();
		});
	}
});
